import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

import ArrowDown from '@static/img/icons/icon_arrow_down.inline.svg'
import ArrowUp from '@static/img/icons/icon_arrow_up.inline.svg'
import ArrowLeft from '@static/img/icons/icon_arrow_left.inline.svg'
import ArrowRight from '@static/img/icons/icon_arrow_right.inline.svg'
import ArrowExternal from '@static/img/icons/icon_arrow_external.inline.svg'
import ChevronRightBig from '@static/img/icons/icon_chevron_big_right.inline.svg'
import ChevronRight from '@static/img/icons/icon_chevron_right.inline.svg'
import ChevronDown from '@static/img/icons/icon_chevron_down.inline.svg'
import ChevronUp from '@static/img/icons/icon_chevron_up.inline.svg'
import EasyLang from '@static/img/icons/icon_easylang.inline.svg'
import Close from '@static/img/icons/icon_close.inline.svg'
import Plus from '@static/img/icons/icon_plus.inline.svg'
import Play from '@static/img/icons/icon_play.inline.svg'
import Pause from '@static/img/icons/icon_pause.inline.svg'
import BackToTop from '@static/img/icons/icon_backtotop.inline.svg'
import Download from '@static/img/icons/icon_download.inline.svg'
import Info from '@static/img/icons/icon_info.inline.svg'
import Home from '@static/img/icons/icon_home.inline.svg'
import CountdownLive from '@static/img/icons/icon_live.inline.svg'
import Quote from '@static/img/icons/icon_quote.inline.svg'
import SpeechBubble from '@static/img/icons/icon_speechbubble.inline.svg'

const IconSet = {
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  ArrowExternal,
  ChevronRightBig,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Close,
  Plus,
  Play,
  Pause,
  EasyLang,
  BackToTop,
  Download,
  Info,
  Home,
  CountdownLive,
  Quote,
  SpeechBubble,
}

export type IconProps = BASE.Helpers.IReactDefaultProps & {
  name: keyof typeof IconSet
  fontSize?: 'inherit' | 'small' | 'large' | 'medium'
  color?: 'primary' | 'inverted' | 'tertiary'
  viewBox?: string
}

export default function Icon({
  name,
  fontSize,
  color,
  viewBox,
  sx,
}: IconProps) {
  const Svg = IconSet[name]

  if (!Svg) return null

  return (
    <SvgIcon
      sx={[
        (theme) => ({
          '& path': {
            transition: '0.2s',
            fill: color
              ? theme.palette.text[color]
              : theme.palette.text.primary,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      fontSize={fontSize}
      component={Svg as React.ElementType<unknown>}
      viewBox={viewBox}
      inheritViewBox={!!!viewBox}
    />
  )
}
