import React, { ReactElement } from 'react'
import { ButtonBase as MuiButton } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'

export type ButtonProps = BASE.Helpers.IReactDefaultProps & {
  id?: string
  to?: string
  hoverInvert?: boolean
  onClick?: (event: React.SyntheticEvent) => void
  isExternal?: boolean
  disabled?: boolean
  isCountdownButton?: boolean
  tabIndex?: number
}

export default function Button({
  id,
  to,
  onClick,
  children,
  isExternal,
  disabled,
  hoverInvert,
  isCountdownButton,
  sx,
  tabIndex,
}: ButtonProps): ReactElement {
  const LinkComponent = !isExternal
    ? GatsbyLink
    : !to && onClick
    ? 'button'
    : 'a'
  const additionalProps = isExternal
    ? { href: to, target: '_blank', rel: 'noreferrer' }
    : { to }

  return (
    <MuiButton
      component={LinkComponent}
      disabled={disabled}
      tabIndex={tabIndex}
      id={id}
      onClick={onClick}
      target={isCountdownButton ? '_blank' : '_self'}
      {...additionalProps}
      sx={[
        (theme) => ({
          ...theme.typography.button,
          color: theme.palette.text.inverted,
          borderRadius: '23px',
          padding: theme.spacing(3, 8),
          transition: '0.3s',
          backgroundImage: `linear-gradient(180deg, ${theme.palette.common.red[500]} 35%, ${theme.palette.common.yellow[500]} 80%)`,
          backgroundSize: '100% 300%',
          backgroundPosition: '0 50%',
          [theme.breakpoints.up('lg')]: {
            '&:hover': {
              backgroundPosition: `0 ${hoverInvert ? '100%' : '0%'}`,
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </MuiButton>
  )
}
