import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@components/core/IconButton'
import { Box } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  ytRoot: {
    margin: theme.spacing(6, -4, 15, -4),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(10, 0, 20, 0),
    },
  },
  ytWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: 'calc(9 / 16 * 100%)',
    overflow: 'hidden',
    '$thumbWrapper, & iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& .uc-embedding-container': {
      position: 'absolute',
      zIndex: 100,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '& img': {
        display: 'none',
      },
      '& .uc-embedding-more-info': {
        width: '80%',
        borderRadius: '25px',
        fontFamily: 'inherit',
        lineHeight: 1.1,
        cursor: 'pointer',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.shorter,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        height: theme.spacing(9),
        padding: theme.spacing(0, 5),
        backgroundColor: '#f3f3f3',
        color: '#aa1932',
        '&:focus': {
          background: '#000000',
          color: '#ffffff',
        },
        '&:not(.disabled):hover': {
          backgroundColor: '#b4b5b6',
        },
        '&.disabled': {
          color: theme.palette.text.primary,
          backgroundColor: '#b4b5b6',
        },
      },
      '& .uc-embedding-accept': {
        width: '80%',
        borderRadius: '25px',
        fontFamily: 'inherit',
        lineHeight: 1.1,
        cursor: 'pointer',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.shorter,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        height: theme.spacing(9),
        padding: theme.spacing(0, 5),
        backgroundColor: '#ffcc00',
        color: 'inherit',
        '&:focus': {
          background: '#000000',
          color: '#ffffff',
        },
        '&:not(.disabled):hover': {
          backgroundColor: '#ffaa32',
          color: 'inherit',
        },
        '&.disabled': {
          color: theme.palette.text.primary,
          backgroundColor: '#b4b5b6',
        },
      },
    },
  },
  thumbWrapper: {
    position: 'relative',
    color: '#ffcc00',
  },
  play: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#ffcc00',
    textAlign: 'center',
  },
}))

export type YouTubeProps = {
  ytid: string
}

function YouTube({ ytid }: YouTubeProps) {
  const classes = useStyles()
  const ytiFrame = useRef()
  const [showiFrame, setShowiFrame] = useState(false)
  const isBrowser = typeof window !== 'undefined'

  if (isBrowser && !!window._paq) {
    window._paq.push(['MediaAnalytics::scanForMedia'])
  }

  function thumbnailClick() {
    ytiFrame?.current?.setAttribute(
      'src',
      ytiFrame.current.getAttribute('src') + '?autoplay=1'
    )
    setShowiFrame(true)
  }
  return (
    <div
      data-track-content
      data-tracking-id={ytid}
      className={clsx(classes.ytRoot)}
      data-testid={'youtube-video'}
    >
      <div className={classes.ytWrapper}>
        <iframe
          ref={ytiFrame}
          src={`https://www.youtube.com/embed/${ytid}`}
          frameBorder="0"
          allowfullscreen
        ></iframe>
        {!showiFrame && (
          <div
            className={classes.thumbWrapper}
            onClick={thumbnailClick}
            data-matomo-title="My custom Video title"
          >
            <Img
              fluid={{
                aspectRatio: 1.7778,
                src: `https://img.youtube.com/vi/${ytid}/maxresdefault.jpg`,
                srcSet: `https://img.youtube.com/vi/${ytid}/maxresdefault.jpg`,
                sizes: '',
                media: `(min-width: 0px)`,
              }}
              backgroundColor={true}
            />
            <Box
              className={classes.play}
              onKeyDown={(e) => {
                if (e.code === 'Space' || e.code === 'Enter') {
                  thumbnailClick()
                  e.preventDefault()
                }
              }}
            >
              <IconButton tabIndex={0} name="Play" />
            </Box>
          </div>
        )}
      </div>
    </div>
  )
}

export default YouTube
