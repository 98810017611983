import { Components } from '@mui/material'

import {
  BundesSansRegular,
  BundesSansBold,
  BundesSansMedium,
  BundesSansBoldRegularItalic,
} from '../../definitions/fontFamily'

export const CssBaselineOverrides = (): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    '@font-face': [BundesSansRegular],
    fallBacks: [
      {
        '@font-face': BundesSansBold,
      },
      {
        '@font-face': BundesSansMedium,
      },
      {
        '@font-face': BundesSansBoldRegularItalic,
      },
    ],
    a: {
      color: 'currentColor',
    },
    strong: {
      fontWeight: 500,
    },
    button: {
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
    },
  },
})
