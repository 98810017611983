import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Box, CssBaseline, Theme } from '@mui/material'

import Header from '@components/modules/global/header'
import Footer from '@components/modules/global/footer'
import IconButton from '@components/core/IconButton'
import BreadCrumbNav from '@components/core/breadcrumb'
import M007Banner from '../../content/M007-Banner'
import NavigationProvider from '@system/providers/NavigationProvider'
import { useIntl } from 'react-intl'

export type LayoutProps = BASE.Helpers.IReactDefaultProps & {
  pageContext: BASE.Helpers.IPageContext
}

const navigationMainGraphQL = graphql`
  query {
    navigationMain: contentfulNavigation(identifier: { eq: "navigationMain" }) {
      node_locale
      pages {
        ... on ContentfulPageDummy {
          ...NavigationFragment
          subPages {
            ... on ContentfulPageArticle {
              ...NavigationFragment
            }
            ... on ContentfulPageExternal {
              ...NavigationFragment
            }
            ... on ContentfulPageDummy {
              ...NavigationFragment
              subPages {
                ... on ContentfulPageGeneric {
                  ...NavigationFragment
                }
                ... on ContentfulPageArticle {
                  ...NavigationFragment
                }
                ... on ContentfulPageExternal {
                  ...NavigationFragment
                }
              }
            }
            ... on ContentfulPageGeneric {
              ...NavigationFragment
              subPages {
                ... on ContentfulPageGeneric {
                  ...NavigationFragment
                }
                ... on ContentfulPageArticle {
                  ...NavigationFragment
                }
                ... on ContentfulPageExternal {
                  ...NavigationFragment
                }
              }
            }
          }
        }
        ... on ContentfulPageGeneric {
          ...NavigationFragment
          subPages {
            ... on ContentfulPageArticle {
              ...NavigationFragment
            }
            ... on ContentfulPageExternal {
              ...NavigationFragment
            }
            ... on ContentfulPageDummy {
              ...NavigationFragment
              subPages {
                ... on ContentfulPageGeneric {
                  ...NavigationFragment
                }
                ... on ContentfulPageArticle {
                  ...NavigationFragment
                }
                ... on ContentfulPageExternal {
                  ...NavigationFragment
                }
              }
            }
            ... on ContentfulPageGeneric {
              ...NavigationFragment
              subPages {
                ... on ContentfulPageGeneric {
                  ...NavigationFragment
                }
                ... on ContentfulPageArticle {
                  ...NavigationFragment
                }
                ... on ContentfulPageExternal {
                  ...NavigationFragment
                }
              }
            }
          }
        }
        ... on ContentfulPageArticle {
          ...NavigationFragment
        }
        ... on ContentfulPageExternal {
          ...NavigationFragment
        }
      }
    }
    navigationFooter: contentfulNavigation(
      identifier: { eq: "navigationFooter" }
    ) {
      node_locale
      pages {
        __typename
        ... on ContentfulPageGeneric {
          ...NavigationFragment
        }
        ... on ContentfulPageExternal {
          ...NavigationFragment
        }
        ... on ContentfulPageExternal {
          ...NavigationFragment
        }
      }
    }
    countdownData: contentfulM005Countdown(
      id: { eq: "637bf5c9-6126-5bb0-8d0b-bcdf312329e8" }
    ) {
      id
      bannerColour
      buttonLabel
      showTimer
      showRedDot
      desktopBeforeEventTitle {
        raw
      }
      duringEventTitle {
        raw
      }
      mobileBeforeEventTitle {
        raw
      }
      postEventTitle {
        raw
      }
      linkToEventPage {
        fields {
          fullPath
        }
      }
    }
  }
`

export default function Layout({ children, pageContext }: LayoutProps) {
  const {
    navigationMain,
    navigationFooter,
    //countdownData
  } = useStaticQuery(navigationMainGraphQL)
  const [scrollDir, setScrollDir] = useState('down')
  const intl = useIntl()

  const [showBackToTop, setShowBackToTop] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > window.innerHeight * 2) {
        setShowBackToTop(true)
      } else {
        setShowBackToTop(false)
      }
    })
  }, [])

  useEffect(() => {
    const threshold = 10
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold && scrollY > 0) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY || scrollY === 0 ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <React.Fragment>
      <CssBaseline />
      <NavigationProvider pages={navigationMain?.pages}>
        <Header navigationItems={navigationMain?.pages} scrollDir={scrollDir} />
      </NavigationProvider>
      <M007Banner scrollDir={scrollDir} />
      <Box
        component="main"
        id="main"
        sx={(muiTheme: Theme) => ({
          '> *': { ...muiTheme.mixins.spacingModule },
        })}
      >
        {pageContext.canonical !== '/' && pageContext.page?.breadCrumbArray && (
          <BreadCrumbNav breadCrumbArray={pageContext.page.breadCrumbArray} />
        )}
        <Box
          tabIndex={-1}
          aria-label={intl.formatMessage({ id: 'button.backToTop' })}
          sx={{
            position: 'sticky',
            height: 0,
            top: '90%',
            width: 'fit-content',
            marginLeft: 'auto',
            zIndex: 100,
          }}
        >
          <Box
            tabIndex={-1}
            sx={{
              overflowX: 'hidden',
              paddingRight: 4,
              transform: 'translateY(-32px)', // bacause parent has height 0, a littel shift to top is needed
            }}
            role="button"
          >
            <IconButton
              tabIndex={0}
              name="BackToTop"
              sx={{
                transition: '0.6s',
                transform: showBackToTop ? 'translateX(0)' : 'translateX(180%)',
              }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                })
              }}
            />
          </Box>
        </Box>
        {children}
      </Box>

      <Footer navigationItems={navigationFooter?.pages} />
    </React.Fragment>
  )
}
