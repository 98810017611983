import React, { ReactElement, useState } from 'react'

import useGlobalText from '@system/hooks/useGlobalText'
import { SwipeableDrawer, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import Link from '@components/core/link'
import Icon from '@components/core/icon'
import { INavItemNodeData } from '.'

export interface IDrawerNavItem extends BASE.Helpers.IReactDefaultProps {
  item: INavItemNodeData
  onNavigate: () => void
  navHeight: number
}

export default function DrawerNavItem({
  item,
  onNavigate,
  navHeight,
  children,
}: IDrawerNavItem): ReactElement {
  const [open, setOpen] = useState(false)
  const { getText } = useGlobalText()

  return (
    <React.Fragment>
      <ListItem
        role="navitem"
        sx={{
          padding: 0,
        }}
      >
        <Typography
          component={Link}
          variant="navigation"
          linkColor="black"
          to={!!item.subPages ? undefined : item.fields?.fullPath}
          onClick={() => {
            if (!!item.subPages) {
              setOpen(true)
            } else {
              onNavigate()
            }
          }}
          icon={
            !!item.subPages
              ? {
                  name: 'ChevronRight',
                  // TODO: FIXME
                  // @ts-expect-error for some reason, this is undefined
                  sx: (theme) => ({
                    fontSize: '1rem',
                    marginRight: theme.spacing(5),
                    marginBottom: theme.spacing(1),
                  }),
                }
              : undefined
          }
          sx={(theme) => ({
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 5,
            py: 5,
            fontSize: theme.fontSize.get('base'),
          })}
        >
          {item.titleNavigation}
        </Typography>
      </ListItem>
      {!!item.subPages && (
        <SwipeableDrawer
          anchor={'right'}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          aria-hidden={!open}
          hideBackdrop
          variant="persistent"
          PaperProps={{
            sx: {
              pt: 20,
              height: navHeight,
              width: '80vw',
              borderBottomLeftRadius: '20px',
            },
          }}
        >
          <Typography
            component="div"
            variant="navigation"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              pl: 4,
              pr: 6,
              py: 5,
              backgroundColor: theme.palette.grey[100],
              //borderBottom: `1px solid ${theme.palette.grey[300]}`,
              fontSize: theme.fontSize.get('base'),
            })}
            onClick={() => setOpen(false)}
          >
            <Icon name="ArrowLeft" sx={{ mr: 2, mb: 1, width: '20px' }} />
            {getText('navigation.back')}
          </Typography>
          {children}
        </SwipeableDrawer>
      )}
    </React.Fragment>
  )
}
