import IconButton from '@components/core/IconButton'
import VideoYouTubeSwitch from '@components/media/videoyoutubeswitch'

import { Backdrop, Box, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'

export type ContentfulYouTubeProps = BASE.Helpers.ModuleDefaultProps & {
  youTubeKey: string
  title?: string
  description?: string
}

export default function ContentfulYouTube({
  youTubeKey,
  title,
  description,
}: ContentfulYouTubeProps) {
  const [isClicked, setIsClicked] = useState(false)
  const isInCarousel = false // change this later for when video is in a carousel module
  const theme = useTheme()

  return (
    <Box
      id={youTubeKey.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}
      sx={{
        '& > .thumbnail': { height: '137px' },
        width: isInCarousel ? '220px' : 'auto',
        marginRight: isInCarousel ? '32px' : '0',
        marginTop: isInCarousel ? '0' : '40px',
        marginBottom: isInCarousel ? '0' : '80px',
      }}
    >
      {isInCarousel && (
        <img
          className="thumbnail"
          src={`https://img.youtube.com/vi/${youTubeKey}/maxresdefault.jpg`}
          alt="preview of video"
          onClick={() => setIsClicked(true)}
        />
      )}
      {isInCarousel && !!title && (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '18px',
            lineHeight: '23px',
            fontFamily: 'BundesSans Bold',
            margin: '10px 0',
            width: '244px',
          }}
        >
          {title}
        </Typography>
      )}

      {!!youTubeKey && !isInCarousel && (
        <VideoYouTubeSwitch
          ytid={youTubeKey}
          src={`https://www.youtube.com/embed/${youTubeKey}`}
        />
      )}

      {isClicked && (
        <Backdrop
          open={isClicked}
          sx={(theme) => ({
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: theme.zIndex.modal,
          })}
        >
          <Box
            sx={{
              padding: '52px 32px',
              margin: '0 10px',
              background: theme.palette.common.white,
              position: 'relative',
              width: '1000px',
              borderRadius: '16px 16px 30px',
            }}
          >
            <Box
              onKeyDown={(e) => {
                if (e.code === 'Space' || e.code === 'Enter') {
                  setIsClicked(false)
                  e.preventDefault()
                }
              }}
            >
              <IconButton
                tabIndex={0}
                name="Close"
                sx={(theme) => ({
                  position: 'absolute',
                  top: theme.spacing(4),
                  right: theme.spacing(4),
                  zIndex: 5,
                  [theme.breakpoints.down('sm')]: {
                    right: theme.spacing(6),
                    width: '48px',
                    height: '48px',
                  },
                })}
                onClick={() => setIsClicked(false)}
              />
            </Box>
            {!!title && (
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                  margin: '10px 0',
                }}
              >
                {title}
              </Typography>
            )}
            {!!description && (
              <Typography
                sx={{ fontSize: '14px', padding: '20px', textAlign: 'center' }}
              >
                {description}
              </Typography>
            )}

            <VideoYouTubeSwitch
              ytid={youTubeKey}
              src={`https://www.youtube.com/watch?v=${youTubeKey}`}
            />
          </Box>
        </Backdrop>
      )}
    </Box>
  )
}
