import BundesSansBoldFont from '@static/font/BundesSans-Web-Bold.woff'
import BundesSansMediumFont from '@static/font/BundesSans-Web-Medium.woff'
import BundesSansRegularFont from '@static/font/BundesSans-Web-Regular.woff'
import BundesSansRegularItalicFont from '@static/font/BundesSans-Web-RegularItalic.woff'

export const BundesSansBold = {
  fontFamily: 'BundesSans Bold',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `url(${BundesSansBoldFont}) format('woff')`,
}

export const BundesSansMedium = {
  fontFamily: 'BundesSans Medium',
  fontDisplay: 'swap',
  src: `url(${BundesSansMediumFont}) format('woff')`,
}

export const BundesSansRegular = {
  fontFamily: 'BundesSans Regular',
  fontDisplay: 'swap',
  src: `url(${BundesSansRegularFont}) format('woff')`,
}

export const BundesSansBoldRegularItalic = {
  fontFamily: 'BundesSans Regular-Italitc',
  fontDisplay: 'swap',
  src: `url(${BundesSansRegularItalicFont}) format('woff')`,
}

export const fontFamilyBold = '"BundesSans Bold", Arial, Sans-Serif'
export const fontFamilyMedium = '"BundesSans Medium", Arial, Sans-Serif'
export const fontFamilyRegular = '"BundesSans Regular", Arial, Sans-Serif'
export const fontFamilyRegularItalic =
  '"BundesSans Regular-Italic", Arial, Sans-Serif'
