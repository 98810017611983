import { Theme } from '@mui/material'
import { MixinsOptions } from '@mui/material/styles/createMixins'
import Breakpoints from '../definitions/breakpoints'
import FontSize from '../definitions/fontSize'

const stripUnit = (value: string): number => Number(value.replace('px', ''))

export default function Mixins(theme: Theme): MixinsOptions {
  return {
    lineClamp: (maxLines) => {
      const supportKey = `@supports (-webkit-line-clamp: ${maxLines})`
      return {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [supportKey]: {
          whiteSpace: 'initial',
          display: '-webkit-box',
          '-webkit-line-clamp': `${maxLines}`,
          '-webkit-box-orient': 'vertical',
        },
      }
    },
    fluidFontSize: (bpMin, bpMax, sizeMin, sizeMax) => {
      const bpMinValue = Breakpoints.values?.[bpMin] || 0
      const bpMaxValue = Breakpoints.values?.[bpMax] || 0

      const sizeMinValue = FontSize.get(sizeMin) as string
      const sizeMaxValue = FontSize.get(sizeMax) as string

      return {
        fontSize: sizeMinValue,
        [theme.breakpoints.up(bpMin)]: {
          fontSize: `calc(${sizeMinValue} + ${
            stripUnit(sizeMaxValue) - stripUnit(sizeMinValue)
          } * ((100vw - ${bpMinValue}px) / ${bpMaxValue - bpMinValue}))`,
        },
        [theme.breakpoints.up(bpMax)]: {
          fontSize: sizeMaxValue,
        },
      }
    },
  }
}
