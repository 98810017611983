import React from 'react'
import Video, { VideoProps } from '../video'
import YouTubePlayer, { YouTubeProps } from '../youtube'

export type VideoYouTubeSwitchProps = VideoProps & YouTubeProps

export default function VideoYouTubeSwitch(
  props: VideoYouTubeSwitchProps
): React.ReactElement {
  if (props.ytid) {
    return <YouTubePlayer ytid={props.ytid} />
  }

  return <Video src={props.src} poster={props.poster} />
}
