import { FontSizeType } from '@mui/material/styles/createTheme'

const FontSize: FontSizeType = new Map([
  ['xs', '13px'],
  ['sm', '14px'],
  ['base', '16px'],
  ['lg', '18px'],
  ['2lg', '20px'],
  ['3lg', '22px'],
  ['xl', '24px'],
  ['2xl', '26px'],
  ['3xl', '28px'],
  ['4xl', '32px'],
  ['5xl', '34px'],
  ['6xl', '40px'],
])

export default FontSize
