import React from 'react'

import Icon, { IconProps } from '@components/core/icon'
import Link, { LinkProps } from '../link'
import { Box } from '@mui/system'
import { Typography } from '@mui/material/styles/createTypography'

export type IconButtonProps = BASE.Helpers.IReactDefaultProps &
  LinkProps & {
    name: IconProps['name']
    hoverInvert?: boolean
    tabIndex?: number
    disabled?: boolean
  }

/**
 * MUI Button Wrapper
 */
export default function IconButton({
  name,
  children,
  hoverInvert,
  to,
  download,
  isExternal,
  tabIndex,
  disabled,
  sx,
  onClick,
}: IconButtonProps): React.ReactElement {
  return (
    <Link
      tabIndex={-1}
      to={to}
      linkColor="inverted"
      onClick={onClick}
      download={download}
      isExternal={isExternal}
      sx={[
        (theme) => ({
          pointerEvents: disabled ? 'none' : 'auto',
          cursor: 'pointer',
          position: 'relative',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          alignItems: 'center',
          transition: '0.3s',
          [theme.breakpoints.up('lg')]: {
            '&:hover': {
              '& span': {
                color: theme.palette.text.hover,
              },
              '& .MuiBox-root': {
                backgroundPosition: `0 ${hoverInvert ? '100%' : '0%'}`,
                '& .MuiSvgIcon-root': {
                  color: theme.palette.text.inverted,
                  '& path': {
                    fill: theme.palette.text.inverted,
                  },
                },
              },
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        tabIndex={tabIndex}
        sx={(theme) => ({
          position: 'relative',
          display: 'inline-block',
          borderRadius: '50%',
          padding: theme.spacing(5),
          minWidth: '56px',
          minHeight: '56px',
          transition: '0.3s',
          backgroundSize: '100% 300%',
          backgroundPosition: '0 50%',
          boxSizing: 'border-box',
          ...(disabled
            ? {
                backgroundImage: `linear-gradient(0deg, ${theme.palette.grey[500]} 0%, ${theme.palette.grey[500]} 100%)`,
              }
            : {
                backgroundImage: `linear-gradient(180deg, ${theme.palette.common.red[500]} 35%, ${theme.palette.common.yellow[500]} 80%)`,
              }),
        })}
      >
        <Icon
          name={name}
          fontSize="small"
          color="inverted"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-48%, -50%)',
          }}
        />
      </Box>
      {!!children && (
        <Box
          component="span"
          sx={(theme) => ({
            ...(theme.typography as Typography).button,
            transition: '0.3s',
            color: theme.palette.text.primary,
            marginLeft: 4,
            paddingRight: 4,
          })}
        >
          {children}
        </Box>
      )}
    </Link>
  )
}
