import { getMinutes, getSeconds } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

export interface ILivestreamStatusContext {
  isLive: boolean
  isFinished: boolean
  isLoaded: boolean
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const LivestreamStatusContext =
  React.createContext<ILivestreamStatusContext>({
    isLive: false,
    isFinished: false,
    isLoaded: false,
    days: 1,
    hours: 1,
    minutes: 1,
    seconds: 1,
  })

export const useCountdown = () => {
  const { timeData } = useStaticQuery(pageQuery)
  const eventStartTimeMS = new Date(timeData.eventStartPicker).getTime()
  const eventEndTimeMS = new Date(timeData.eventEndPicker).getTime()

  const countDownDate = new Date(
    eventStartTimeMS - new Date().getTime()
  ).getTime()

  const countDownDateMilliseconds = new Date(eventStartTimeMS).getTime()
  const [countDown, setCountDown] = useState(
    countDownDateMilliseconds - new Date().getTime()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date().getTime() > eventEndTimeMS) {
        setCountDown(-1) // ends the countdown after event is finished
      } else {
        setCountDown(countDownDateMilliseconds - new Date().getTime())
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown, eventEndTimeMS)
}

const getReturnValues = (countDown: number, eventEndTimeMS: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = getMinutes(countDown)
  const seconds = getSeconds(countDown)

  return [days, hours, minutes, seconds, eventEndTimeMS]
}

export type LivestreamStatusProviderProps = {
  children: React.ReactNode
}

export default function LivestreamStatusProvider({
  children,
}: LivestreamStatusProviderProps): React.ReactElement {
  const [isLive, setIsLive] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [day, setDay] = useState(5000)
  const [hour, setHour] = useState(5000)
  const [minute, setMinute] = useState(5000)
  const [second, setSecond] = useState(5000)
  const [days, hours, minutes, seconds, eventEndTimeMS] = useCountdown()

  useEffect(() => {
    setDay(days)
    setHour(hours)
    setMinute(minutes)
    setSecond(seconds)

    if (day !== 5000) {
      setIsLoaded(true)
    }

    if ((day < 0 && hour < 0) || day + hour + minute + second <= 0) {
      setIsLive(true)
    }

    if (new Date().getTime() > eventEndTimeMS) {
      setIsFinished(true)
      setIsLive(false)
    }
  }, [days, hours, minutes, seconds])

  return (
    <LivestreamStatusContext.Provider
      value={{
        isLive: isLive,
        isFinished: isFinished,
        isLoaded: isLoaded,
        days: day,
        hours: hour,
        minutes: minute,
        seconds: second,
      }}
    >
      {children}
    </LivestreamStatusContext.Provider>
  )
}
export const pageQuery = graphql`
  query {
    timeData: contentfulM005Countdown(
      id: { eq: "637bf5c9-6126-5bb0-8d0b-bcdf312329e8" }
    ) {
      eventStartPicker(formatString: "ddd MMM D, YYYY HH:mm:ss")
      eventEndPicker(formatString: "ddd MMM D, YYYY HH:mm:ss")
    }
  }
`
