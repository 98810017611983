import React, { useRef } from 'react'

import { Backdrop, Box, Grid } from '@mui/material'

import NavItemLevel1 from './parts/navItemLevel1'
import useNavigation from '@system/hooks/useNavigation'

export default function HorizontalNav() {
  const CompRef = useRef<HTMLElement>(null)

  const navigationValues = useNavigation()
  return (
    <Box
      component="nav"
      ref={CompRef}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.main,
      })}
      onMouseMove={() => {
        if (navigationValues.activeItem) {
          navigationValues.setActiveItem(undefined)
        }
      }}
    >
      <Backdrop
        open={
          navigationValues.stateLevel > 1 ||
          !!navigationValues.activeItem?.children
        }
        onClick={() => navigationValues.setActiveItem(undefined)}
        sx={() => ({ zIndex: -1 })}
      />
      <Grid
        component="ul"
        container
        direction="row"
        role="menubar"
        aria-label="Hauptnavigation"
        sx={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
          alignItems: 'baseline',
        }}
      >
        {navigationValues.items.map((item) => {
          return (
            <NavItemLevel1
              key={item.id}
              item={item}
              isOpen={navigationValues.isActiveBreadcrumb(item.id)}
              onFocus={navigationValues.focusHandler}
              onBlur={navigationValues.blurHandler}
            />
          )
        })}
      </Grid>
    </Box>
  )
}
