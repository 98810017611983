import React, { useEffect, useRef, useState } from 'react'

import { Box, Typography } from '@mui/material'

import Link from '@components/core/link'
import NavItemLevel3 from './navitemLevel3'
import useNavigation from '@system/hooks/useNavigation'

export interface INavItem {
  item: BASE.Provider.INavItem
  closeNavWindow: () => void
  onFocus: React.FocusEventHandler<HTMLElement>
  onBlur: React.FocusEventHandler<HTMLElement>
}

export default function NavItemLevel2({
  item,
  closeNavWindow,
  onFocus,
  onBlur,
}: INavItem) {
  const [isHoveredLvl2, setIsHoveredLvl2] = useState(false)
  const ref = useRef<HTMLLIElement | undefined>()
  const navigationValues = useNavigation()

  function renderSubNavi(): JSX.Element | null {
    if (!item.children) return null

    return (
      <Box
        component="ul"
        sx={(theme) => ({
          position: 'absolute',
          left: '100%',
          minWidth: '400px',
          minHeight: '100%',
          top: 0,
          display: isHoveredLvl2 ? 'block' : 'none',
          listStyle: 'none',
          margin: 0,
          padding: theme.spacing(10, 10, 6, 10),
          zIndex: -1,
          backgroundColor: theme.palette.background.light,
          borderRadius: `0px 0px 20px 20px`,
        })}
        role={!!item.children ? 'list' : 'link'}
        tabIndex={!!item.url ? 0 : -1}
      >
        {!!item.url && (
          <NavItemLevel3
            item={{
              id: item.id,
              label: item.label,
              url: item.url,
              index: item.index,
              breadcrumbs: item.breadcrumbs,
            }}
            closeNavWindow={closeNavWindow}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
        {!!item.children &&
          item.children.map((item) => {
            return (
              <NavItemLevel3
                key={item?.id}
                item={item}
                closeNavWindow={closeNavWindow}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )
          })}
      </Box>
    )
  }

  useEffect(() => {
    if (navigationValues.isActiveItem(item.id)) {
      setIsHoveredLvl2(true)
      if (ref.current === document.activeElement) return
      setTimeout(() => {
        ref.current?.focus()
      }, 0)
    } else {
      setIsHoveredLvl2(navigationValues.isActiveBreadcrumb(item.id))
    }
  }, [item.id, navigationValues.activeItem])

  return (
    <Box
      id={item.id}
      component="li"
      ref={ref}
      onMouseEnter={() => {
        if (!!item.children) {
          setIsHoveredLvl2(true)
        }
      }}
      onMouseLeave={() => {
        if (!navigationValues.isActiveBreadcrumb(item.id)) {
          setIsHoveredLvl2(false)
        }
      }}
      onClick={() => {
        if (!item.children) {
          closeNavWindow()
        }
      }}
      role={item.children ? 'menubar' : 'link'}
      onFocus={onFocus}
      //onBlur={onBlur}
      tabIndex={!!item.children ? 0 : -1}
      onKeyDown={navigationValues.keyHandler}
    >
      <Typography
        component={Link}
        tabIndex={!!item.children ? -1 : 0}
        variant="navigation"
        linkColor="black"
        to={!!item.children ? undefined : item.url}
        icon={
          !!item.children
            ? {
                name: 'ChevronRight',
                // TODO: FIXME
                sx: (theme) => ({
                  marginLeft: theme.spacing(15),
                  marginRight: theme.spacing(5),
                }),
              }
            : undefined
        }
        sx={(theme) => ({
          cursor: 'pointer',
          zIndex: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: !!item.children ? '100%' : 'fit-content',
          textAlign: 'left',
          padding: !!item.children
            ? theme.spacing(1.5, 0, 1.5, 10)
            : theme.spacing(2, 4),
          marginX: !!item.children ? '0' : theme.spacing(6),
          borderRadius: !!item.children ? '0 18px 18px 0' : '18px',
          transition: '0.2s',
          whiteSpace: 'nowrap',
          marginBottom: theme.spacing(2),
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            marginTop: '3px',
          },
          [theme.breakpoints.up('lg')]: {
            '&:focus, &:hover': {
              backgroundColor: theme.palette.background.red,
              color: theme.palette.text.inverted,
              width: !!item.children ? 'calc(100% + 16px)' : 'fit-content',
              '& .MuiSvgIcon-root': {
                '& path': {
                  fill: theme.palette.text.inverted,
                },
              },
            },
          },
        })}
      >
        {item.label}
      </Typography>
      {renderSubNavi()}
    </Box>
  )
}
