import { Components, Theme } from '@mui/material'
import { TypeText } from '@mui/material/styles/createPalette'

export const AccordionOverrides = (
  theme: Theme
): Components['MuiAccordion'] => ({
  defaultProps: {
    square: true,
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      color: (theme.palette.text as TypeText).main,
      backgroundColor: 'white',
      padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
      border: '1px solid',
      borderColor: theme.palette.background.secondary,
      borderRadius: '8px',
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0,
      },
      '&.MuiAccordion-outlined': {
        boxShadow:
          '0px -1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      },
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(8)} ${theme.spacing(8)}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${theme.spacing(9)} ${theme.spacing(13.5)}`,
      },
      '&::before': {
        backgroundColor: 'transparent',
      },
      '&.MuiPaper-root': {
        marginBottom: 16,
        padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
        [theme.breakpoints.up('lg')]: {
          marginBottom: 24,
          padding: `${theme.spacing(7)} ${theme.spacing(16)}`,
        },
      },
    },
  },
})

export const AccordionSummaryOverrides =
  (): Components['MuiAccordionSummary'] => ({
    styleOverrides: {
      root: {
        padding: 0,
        minHeight: 0,
      },
      content: {
        margin: 0,
      },
      expandIconWrapper: {
        svg: {
          fill: 'currentColor',
        },
      },
    },
  })

export const AccordionDetailsOverrides = (
  theme: Theme
): Components['MuiAccordionDetails'] => ({
  styleOverrides: {
    root: {
      padding: theme.spacing(4, 0, 0, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5, 0, 0, 0),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(8, 0, 0, 0),
      },
      '&.MuiTypography-root': {
        //fontSize: FontSize.get('base'),
        [theme.breakpoints.up('lg')]: {
          //fontSize: '18px',
        },
      },
    },
  },
})
