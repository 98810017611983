import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Theme } from '@mui/material'
import {
  fontFamilyBold,
  fontFamilyMedium,
  fontFamilyRegular,
  fontFamilyRegularItalic,
} from '../definitions/fontFamily'
import FontSize from '../definitions/fontSize'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    navigation: React.CSSProperties
    navigation2: React.CSSProperties
    footer: React.CSSProperties
    chip: React.CSSProperties
    chip2: React.CSSProperties
    quote: React.CSSProperties
    teaser: React.CSSProperties
    caption2: React.CSSProperties
    intro: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    navigation?: React.CSSProperties
    navigation2?: React.CSSProperties
    footer?: React.CSSProperties
    chip?: React.CSSProperties
    chip2?: React.CSSProperties
    quote?: React.CSSProperties
    teaser?: React.CSSProperties
    caption2?: React.CSSProperties
    intro?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    navigation: true
    navigation2: true
    footer: true
    chip: true
    chip2: true
    quote: true
    teaser: true
    subline: true
    caption2: true
    intro: true
  }
}

export const TypographyOverrides = (theme: Theme): TypographyOptions => {
  return {
    ...theme.typography,
    fontFamilyRegular: fontFamilyRegular,
    fontFamilyBold: fontFamilyBold,
    fontFamilyMedium: fontFamilyMedium,
    fontFamilyItalic: fontFamilyRegularItalic,
    body1: {
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('base'),
      lineHeight: 1.55,
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.625,
      },
    },
    body2: {
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('base'),
      lineHeight: 1.55,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'),
        lineHeight: 1.625,
      },
    },
    h1: {
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('4xl'),
      lineHeight: 1.2,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('6xl'),
      },
    },
    h2: {
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('3xl'),
      lineHeight: 1.2,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'),
      },
    },
    h3: {
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('3lg'),
      lineHeight: 1.3,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('xl'),
      },
    },
    h4: {
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('3lg'),
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
    },
    h5: {
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('lg'),
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
    },
    intro: {
      fontSize: FontSize.get('3lg'),
      lineHeight: 1.2,
      marginBottom: theme.spacing(12),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('3xl'),
        lineHeight: 1.286,
        marginBottom: theme.spacing(20),
      },
    },
    teaser: {
      fontSize: FontSize.get('sm'),
      lineHeight: 1.57,
      color: theme.palette.text.secondary,
    },
    info: {
      fontSize: FontSize.get('xs'),
      lineHeight: 1.23,
      color: theme.palette.text.secondary,
    },
    quote: {
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('3xl'),
      lineHeight: 1.33,
      color: theme.palette.primary.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'),
        lineHeight: 1.25,
      },
    },
    button: {
      textTransform: 'none',
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('lg'),
      lineHeight: 1.3,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('2lg'),
      },
    },
    caption: {
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('sm'),
      lineHeight: 1.14,
      color: theme.palette.text.secondary,
    },
    navigation: {
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('lg'),
      lineHeight: 1.3,
    },
    breadcrumb: {
      fontSize: FontSize.get('base'),
      lineHeight: 1.2,
    },
    footer: {
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('base'),
      lineHeight: 1.3,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'),
      },
    },
  }
}
