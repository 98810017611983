import React, { useRef, useState, useEffect } from 'react'
import { Box, useTheme, useMediaQuery } from '@mui/material'
import IconButton from '@components/core/IconButton'

export type VideoProps = BASE.Helpers.IReactDefaultProps &
  Partial<HTMLVideoElement> & {
    src: string
    srcMobile?: string
    triggerPlay?: boolean
    playsInline?: boolean
    tabIndex?: number
  }

export default function Video({
  src,
  srcMobile,
  poster,
  controls,
  autoplay,
  muted,
  loop,
  playsInline,
  triggerPlay,
  tabIndex,
  sx,
}: VideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoPlay, setVideoPlay] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (triggerPlay) {
      videoRef.current?.play()
      setVideoPlay(true)
    } else {
      videoRef.current?.pause()
      setVideoPlay(false)
    }
  }, [triggerPlay])

  useEffect(() => {
    if (videoRef.current && autoplay) {
      videoRef.current.play()
      videoRef.current.muted = true
      videoRef.current.loop = !!loop
    }
  }, [])

  function toggleVideo() {
    if (!videoPlay && controls) {
      videoRef.current?.play()
      setVideoPlay(true)
    }
  }

  return (
    <Box
      tabIndex={tabIndex}
      ref={videoRef}
      component="video"
      controls={!!controls}
      autoPlay={autoplay}
      muted={muted || autoplay}
      playsInline={playsInline}
      onClick={() => {
        toggleVideo()
      }}
      src={!!srcMobile && isMobile ? srcMobile : src}
      sx={[
        (theme) => ({
          position: 'relative',
          height: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          [theme.breakpoints.up('lg')]: {
            width: '100%',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!videoPlay && (
        <Box
          sx={{
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: '8px',
            left: 0,
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {!!poster && (
            <img aria-hidden="true" width="100%" height="auto" src={poster} />
          )}
          <Box
            className="playpause"
            sx={(theme) => ({
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              opacity: 1,
              transition: 'opacity 400ms',
              [theme.breakpoints.up('lg')]: {
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            })}
          >
            <IconButton tabIndex={0} name="Play" />
          </Box>
        </Box>
      )}
    </Box>
  )
}
