import React from 'react'

import { Box } from '@mui/system'
import Link from '../link'
import Icon from '../icon'
import { Container, Grid } from '@mui/material'

export type BreadCrumbProps = BASE.Helpers.IReactDefaultProps & {
  breadCrumbArray: Array<{
    titleNavigation: string
    fullPath: string
  }>
}

export default function BreadCrumbNav({ breadCrumbArray }: BreadCrumbProps) {
  return (
    <Container component="nav" aria-label="Breadcrumb">
      <Grid
        zIndex={-1}
        component="ul"
        container
        direction="row"
        wrap="wrap"
        alignItems="center"
        sx={{
          listStyle: 'none',
          px: 0,
          my: 5,
        }}
      >
        {breadCrumbArray.map((crumb, index) => {
          return (
            <Box
              key={crumb.fullPath}
              component="li"
              sx={{ width: 'fit-content' }}
            >
              <Box
                component={index === breadCrumbArray.length - 1 ? 'span' : Link}
                aria-hidden={
                  index === breadCrumbArray.length - 1 ? true : false
                }
                to={crumb.fullPath}
                linkColor="black"
                sx={(theme) => ({
                  color:
                    index === breadCrumbArray.length - 1
                      ? theme.palette.text.tertiary
                      : theme.palette.text.primary,
                })}
              >
                {index !== 0 && (
                  <Icon
                    name="ChevronRight"
                    sx={{ height: '12px', mx: 5, mb: '2px' }}
                  />
                )}
                {index === 0 ? (
                  <Box tabIndex={0}>
                    <Icon
                      name="Home"
                      viewBox="0 0 46 48"
                      sx={{ marginBottom: '3px' }}
                    />
                  </Box>
                ) : (
                  crumb.titleNavigation
                )}
              </Box>
            </Box>
          )
        })}
      </Grid>
    </Container>
  )
}
