import { Components, Theme } from '@mui/material'
import { alpha } from '@mui/system'

export const MuiFormControlOverrides = (): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      width: '100%',
    },
  },
})

export const MuiInputBaseOverrides = (
  theme: Theme
): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: {
      padding: 0,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
})

export const MuiOutlinedInputOverrides = (
  theme: Theme
): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '&:before': {
        borderBottom: '1px solid',
      },
      '&:after': {
        borderBottom: '1px solid',
      },
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    input: {
      cursor: 'pointer',
      padding: 0,
    },
  },
})

export const MuiFilledInputOverrides = (
  theme: Theme
): Components['MuiFilledInput'] => ({
  styleOverrides: {
    root: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '&:before': {
        borderBottom: '1px solid',
      },
      '&:after': {
        borderBottom: '1px solid',
      },
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    input: {
      cursor: 'pointer',
      padding: 0,
    },
  },
})

export const MuiFormHelperTextOverrides = (
  theme: Theme
): Components['MuiFormHelperText'] => ({
  styleOverrides: {
    root: {
      marginLeft: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
})

export const MuiInputLabelOverrides = (
  theme: Theme
): Components['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.grey[300],
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      '&.Mui-focused, &.MuiInputLabel-shrink': {
        fontSize: '12px',
        color: theme.palette.grey[500],
        transform: 'translate(12px, 9px) scale(1)',
      },
      '&.Mui-error': {
        color: theme.palette.grey[500],
      },
    },
    asterisk: {
      //color: theme.palette.primary.main,
    },
  },
})
