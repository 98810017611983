import React, { ReactElement, useState } from 'react'
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby'

import HomeLogo from '@static/img/logos/homelogo.svg'

import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  useMediaQuery,
  Theme,
  SvgIcon,
  Typography,
  Box,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

import HorizontalNav from '@components/modules/global/navigation/horizontalNav'
import DrawerNav from '@components/modules/global/navigation/drawerNav'
import { INavItemNodeData } from '@components/modules/global/navigation/drawerNav'
import Burger from '@components/core/burger'

import Link from '@components/core/link'
import useGlobalText from '@system/hooks/useGlobalText'
import PopUpVideo from '@components/media/popUpVideo'
import Button from '@components/core/button'
import { FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  skipLink: {
    [theme.breakpoints.up(1024)]: {
      '& div': {
        height: '0',
        marginLeft: '10px',
        '&:focus-visible': {
          height: 'auto',
          width: 'fit-content',
        },
      },
    },
  },
}))

export type HeaderProps = {
  navigationItems: Array<INavItemNodeData>
  scrollDir: string
}

const HeaderGraphQl = graphql`
  query {
    headerVideo: contentfulGlobalAsset(key: { eq: "video.discussion" }) {
      media {
        localFile {
          publicURL
        }
      }
    }
    easyLangPage: contentfulPageGeneric(
      id: { eq: "2005b48b-3c20-55eb-81d4-b3f4f11f710b" }
    ) {
      id
      titleNavigation
      fields {
        fullPath
      }
    }
  }
`

export default function Header({
  navigationItems,
  scrollDir,
}: HeaderProps): ReactElement {
  const { easyLangPage, headerVideo } = useStaticQuery<{
    easyLangPage: INavItemNodeData
    headerVideo: {
      media: BASE.Contentful.IAsset
    }
  }>(HeaderGraphQl)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [triggerVideoPlay, setTriggerVideoPlay] = useState(false)
  const { getText } = useGlobalText()
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const intl = useIntl()
  const classes = useStyles()

  return (
    <AppBar
      elevation={0}
      position={scrollDir === 'up' ? 'sticky' : 'relative'}
      color="inherit"
      sx={(theme) => ({
        zIndex: theme.zIndex.modal,
        boxShadow: '0px 0px 10px 0px rgba(75, 75, 75, 0.18)',
        '@keyframes slideIn': {
          from: {
            transform: 'translateY(-100px)',
          },
          to: {
            transform: 'translateY(0)',
          },
        },
        animation: scrollDir === 'up' ? 'slideIn 0.5s linear' : 'none',
      })}
    >
      <GatsbyLink to="#main" className={classes.skipLink}>
        <Box tabIndex={0}>
          <FormattedMessage id="header.skiplink.text" />
        </Box>
      </GatsbyLink>
      <Toolbar
        disableGutters
        sx={(theme) => ({
          backgroundColor: theme.palette.background.main,
          padding: theme.spacing(6, 8, 6, 4),

          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(6, 8),
          },
        })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          {!isLarge ? (
            <React.Fragment>
              <DrawerNav
                items={navigationItems}
                openState={drawerOpen}
                toggleDrawer={setDrawerOpen}
                handleVideoClick={() => setTriggerVideoPlay(true)}
                easyLang={{
                  label: easyLangPage.titleNavigation,
                  to: easyLangPage.fields.fullPath,
                }}
              />

              <GatsbyLink to="/">
                <Box tabIndex={0}>
                  <SvgIcon
                    sx={{
                      height: '100%',
                      width: '100%',
                      maxWidth: '135px',
                    }}
                    component={HomeLogo as React.ElementType<unknown>}
                    viewBox="0 0 216 49"
                  />
                </Box>
              </GatsbyLink>
              <IconButton
                tabIndex={0}
                role="button"
                size="small"
                aria-label={intl.formatMessage({
                  id: 'burger.navigation.ariaLabel',
                })}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setDrawerOpen(!drawerOpen)}
                color="inherit"
                sx={{
                  marginLeft: 'auto',
                  mr: -1,
                }}
              >
                <Burger active={drawerOpen} />
              </IconButton>
              {triggerVideoPlay && (
                <PopUpVideo
                  media={headerVideo.media}
                  triggerVideoPlay={triggerVideoPlay}
                  handleVideoClickToClose={() => setTriggerVideoPlay(false)}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GatsbyLink to="/">
                <Box tabIndex={0}>
                  <SvgIcon
                    sx={{
                      width: '100%',
                      height: '100%',
                      maxWidth: '216px',
                    }}
                    component={HomeLogo as React.ElementType<unknown>}
                    viewBox="0 0 216 49"
                  />
                </Box>
              </GatsbyLink>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :last-child': { margin: '0 32px' },
                }}
              >
                <Grid item xs={8} component={HorizontalNav} />
                <Button
                  tabIndex={-1}
                  to={getText('button.zeitstrahlUrl')}
                  sx={(theme) => ({
                    ...theme.typography.button,
                    color: theme.palette.text.inverted,
                    borderRadius: '23px',
                    padding: theme.spacing(3, 8),
                    transition: '0.3s',
                    backgroundImage: `linear-gradient(180deg, ${theme.palette.common.red[500]} 35%, ${theme.palette.common.yellow[500]} 80%)`,
                    backgroundSize: '100% 300%',
                    backgroundPosition: '0 50%',
                    [theme.breakpoints.up('lg')]: {
                      '&:hover': {
                        backgroundPosition: '0 0%',
                      },
                    },
                  })}
                >
                  {getText('button.zeitstrahlLabel')}
                </Button>
                {/* <Box
                  onClick={() => {
                    setTriggerVideoPlay(true)
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setTriggerVideoPlay(true)
                    } else if (e.key === 'Escape') {
                      setTriggerVideoPlay(false)
                      e.preventDefault()
                    }
                  }}
                >
                  <Button
                    tabIndex={-1}
                    sx={(theme) => ({
                      ...theme.typography.button,
                      color: theme.palette.text.inverted,
                      borderRadius: '23px',
                      padding: theme.spacing(3, 8),
                      transition: '0.3s',
                      backgroundImage: `linear-gradient(180deg, ${theme.palette.common.red[500]} 35%, ${theme.palette.common.yellow[500]} 80%)`,
                      backgroundSize: '100% 300%',
                      backgroundPosition: '0 50%',
                      [theme.breakpoints.up('lg')]: {
                        '&:hover': {
                          backgroundPosition: '0 0%',
                        },
                      },
                    })}
                  >
                    {getText('button.openDiscussion')}
                  </Button>
                </Box> */}
              </Box>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                direction="row"
                xs={2}
              >
                <Typography
                  component={Link}
                  linkColor="black"
                  variant="navigation"
                  to={easyLangPage.fields.fullPath}
                  icon={{ name: 'EasyLang', sx: { width: '16px' } }}
                  iconLeft
                >
                  <Box tabIndex={0} display="inline">
                    {easyLangPage.titleNavigation}
                  </Box>
                </Typography>
              </Grid>
              {triggerVideoPlay && (
                <PopUpVideo
                  media={headerVideo.media}
                  triggerVideoPlay={triggerVideoPlay}
                  handleVideoClickToClose={() => setTriggerVideoPlay(false)}
                />
              )}
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
