import { Backdrop, Box } from '@mui/material'
import React, { ReactElement, useEffect } from 'react'

import IconButton from '@components/core/IconButton'
import Video from '@components/media/video'
import ContentfulYouTube, {
  ContentfulYouTubeProps,
} from '@components/modules/content/ContentfulYouTube'

export type PopUpVideoProps = {
  media?: BASE.Contentful.IAsset
  mediaMobile?: BASE.Contentful.IAsset
  mediaYouTube?: ContentfulYouTubeProps
  triggerVideoPlay: boolean
  handleVideoClickToClose: () => void
}

export default function PopUpVideo({
  media,
  mediaMobile,
  mediaYouTube,
  triggerVideoPlay,
  handleVideoClickToClose,
}: PopUpVideoProps): ReactElement {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleVideoClickToClose()
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleVideoClickToClose])

  return (
    <Backdrop
      aria-hidden={!triggerVideoPlay}
      open={triggerVideoPlay}
      onClick={(ev) => {
        // @ts-expect-error tagName is not available on target, but it is
        if (ev.target.tagName !== 'VIDEO' && !mediaYouTube) {
          handleVideoClickToClose()
        }
      }}
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.modal,
      })}
    >
      <Box
        role="video"
        sx={(theme) => ({
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          maxWidth: theme.maxWidth.lg,
          height: 'auto',
          transform: 'translate(-50%, -50%)',
          // overflow: 'hidden',
        })}
      >
        <Box
          onKeyDown={(e) => {
            if (e.code === 'Space' || e.code === 'Enter') {
              handleVideoClickToClose()
              e.preventDefault()
            }
          }}
        >
          <IconButton
            tabIndex={0}
            name="Close"
            sx={(theme) => ({
              position: 'absolute',
              top: theme.spacing(4),
              right: theme.spacing(4),
              zIndex: 5,
              [theme.breakpoints.down('sm')]: {
                right: theme.spacing(2),
                width: '48px',
                height: '48px',
                top: theme.spacing(-14),

                '> div': {
                  minWidth: '36px',
                  minHeight: '36px',
                },

                '> div > svg': {
                  width: '0.8em',
                  height: '0.8em',
                },
              },
            })}
            onClick={() => handleVideoClickToClose()}
          />
        </Box>
        {!!media && !!media.localFile && (
          <Video
            src={media.localFile.publicURL}
            srcMobile={
              mediaMobile && mediaMobile.localFile
                ? mediaMobile.localFile.publicURL
                : media.localFile.publicURL
            }
            triggerPlay={triggerVideoPlay}
            loop
            controls
            sx={(theme) => ({
              width: '95%',
              height: 'auto',
              borderRadius: '16px 16px 30px',
              [theme.breakpoints.up('md')]: {
                width: '100%',
              },
            })}
          />
        )}
        <Box
          sx={{
            px: { xs: 4, md: 0 },
            '> div, > div > div': {
              m: 0,
            },
          }}
        >
          {!!mediaYouTube && !media && <ContentfulYouTube {...mediaYouTube} />}
        </Box>
      </Box>
    </Backdrop>
  )
}
