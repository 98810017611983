import React, { ReactElement } from 'react'

import { Box } from '@mui/material'
import theme from '@config/theme'

interface IBurger {
  active: boolean
}

export default function Burger({ active }: IBurger): ReactElement {
  return (
    <Box
      data-testid="burger"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        height: theme.spacing(4.5),
        width: theme.spacing(5),
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '3px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '120%',
            transform: 'translateY(4px) translateX(-2px) rotate(45deg)',
          }),
        }}
      ></Box>

      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '3px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '120%',
            transform: 'translateY(-5px) translateX(-2px) rotate(-45deg)',
          }),
        }}
      ></Box>
    </Box>
  )
}
