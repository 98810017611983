import { Theme } from '@mui/material/'
import {
  ColorPartial,
  PaletteOptions,
} from '@mui/material/styles/createPalette'

const Colors: Record<string, ColorPartial | string> = {
  red: {
    500: '#e11414',
  },
  darkRed: {
    500: '#aa1932',
  },
  orange: {
    500: '#ffaa32',
  },
  yellow: {
    500: '#ffcc00',
  },
  grey: {
    100: '#f3f3f3',
    300: '#e2e3e5',
    500: '#b4b5b6',
    700: '#4b4b4b',
  },
  black: '#000000',
  white: '#ffffff',
}
export const PaletteOverrides = (theme: Theme): PaletteOptions => ({
  ...theme.palette,
  common: Colors,
  mode: 'light',
  primary: {
    main: (Colors.black as string) || '',
  },
  secondary: {
    main: Colors.grey[500],
    ...(Colors.grey as ColorPartial),
  },
  tertiary: {
    main: Colors.red[500],
    dark: Colors.darkRed[500],
  },
  background: {
    ...theme.palette.background,
    main: theme.palette.common.white,
    primary: Colors.black as string,
    secondary: Colors.grey[500],
    medium: Colors.grey[300],
    light: Colors.grey[100],
    red: Colors.red[500],
  },
  text: {
    ...theme.palette.text,
    primary: Colors.black as string,
    secondary: Colors.grey[500],
    tertiary: Colors.red[500],
    disabled: Colors.white as string,
    inverted: Colors.white as string,
    hover: Colors.darkRed[500],
  },
  gradient: {
    primary: {
      topdown: `linear-gradient(-180deg, ${Colors.red[500]} 0%, ${Colors.yellow[500]} 100%)`,
      leftright: `linear-gradient(90deg, ${Colors.red[500]} 0%, ${Colors.yellow[500]} 100%)`,
    },
  },
})
