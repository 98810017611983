import React, { ReactElement } from 'react'

import { WrapPageElementNodeArgs, Script, ScriptStrategy } from 'gatsby'

import Layout from '@components/modules/global/layout'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import GlobalTextProvider from '@system/providers/GlobalTextProvider'
import LangSwitchProvider from '@system/providers/LangSwitchProvider'
import LivestreamStatusProvider from '@system/providers/LivestreamStatusProvider'
import TrackingProvider from '@system/providers/TrackingProvider'

import theme from '@config/theme'
import { IntlProvider } from 'react-intl'
import { messages } from '@system/translations'

type PageWrapperProps = WrapPageElementNodeArgs & {
  props: {
    pageContext: BASE.Helpers.IPageContext
    location: Record<string, boolean>
  }
}

export default function PageWrapper({
  element,
  props,
}: PageWrapperProps): ReactElement {
  return (
    <React.Fragment>
      <Script
        id="usercentrics-cmp"
        data-settings-id="Pmf7NafD1"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-tcf-enabled
        strategy={ScriptStrategy.idle}
      />
      <Script
        key="usercentrics-sdp"
        type="application/javascript"
        src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
        strategy={ScriptStrategy.idle}
      />
      <IntlProvider locale={'de'} defaultLocale={'de'} messages={messages.de}>
        <ThemeProvider theme={theme}>
          <TrackingProvider {...props}>
            <CssBaseline />
            <GlobalTextProvider pageContext={props.pageContext}>
              <LangSwitchProvider>
                <LivestreamStatusProvider>
                  <Layout pageContext={props.pageContext}>{element}</Layout>
                </LivestreamStatusProvider>
              </LangSwitchProvider>
            </GlobalTextProvider>
          </TrackingProvider>
        </ThemeProvider>
      </IntlProvider>
    </React.Fragment>
  )
}
