import { useContext } from 'react'

import {
  INavigationContext,
  NavigationContext,
} from '@system/providers/NavigationProvider'

export default function useNavigation(): INavigationContext {
  const values = useContext(NavigationContext)

  return values
}
