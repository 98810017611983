import React, { useEffect, useRef } from 'react'

import { Box, Typography } from '@mui/material'

import Link from '@components/core/link'
import useNavigation from '@system/hooks/useNavigation'

export interface INavItem {
  item: BASE.Provider.INavItem
  closeNavWindow: () => void
  onFocus: React.FocusEventHandler<HTMLElement>
  onBlur: React.FocusEventHandler<HTMLElement>
}

export default function NavItemLevel3({
  item,
  onFocus,
  //onBlur,
  closeNavWindow,
}: INavItem) {
  if (!item) return null
  const ref = useRef<HTMLLIElement | undefined>()
  const navigationValues = useNavigation()

  useEffect(() => {
    if (navigationValues.isActiveItem(item.id)) {
      if (ref.current === document.activeElement) return
      setTimeout(() => {
        ref.current?.focus()
      }, 0)
    }
  }, [item.id, navigationValues.activeItem])

  return (
    <Box
      id={item.id}
      component="li"
      role="link"
      ref={ref}
      sx={(theme) => ({
        marginBottom: theme.spacing(2),
        width: 'fit-content',
      })}
      tabIndex={0}
      onKeyDown={() => navigationValues.keyHandler}
      onFocus={onFocus}
      //onBlur={onBlur}
    >
      <Typography
        component={Link}
        tabIndex={-1}
        onClick={closeNavWindow}
        linkColor="black"
        variant="navigation"
        sx={(theme) => ({
          position: 'relative',
          display: 'block',
          borderRadius: '18px',
          padding: theme.spacing(1.5, 4),
          fontWeight: 'normal',
          [theme.breakpoints.up('lg')]: {
            '&:hover': {
              backgroundColor: theme.palette.background.red,
              color: theme.palette.text.inverted,
            },
          },
        })}
        to={item.url}
      >
        {item.label}
      </Typography>
    </Box>
  )
}
